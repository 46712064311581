.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.exercice legend, .center legend {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  float: none;
  font-size: 1.2em;
  margin-bottom: 0;
}

fieldset.center, .exercice fieldset{
  margin-bottom: 1em;
}

.exercice label, .center label, .App label {
  margin-right: 0.5em;
  display: block;
}

.exercice input, .center input {
  margin-right: 0.2em;
}

form{
  margin-bottom: 1em;
}

.presentationForm{
  width: 95%;
  margin: auto;
}
.MuiFormControl-root{
  margin-bottom: 1em !important;
}

ul{
  list-style-position: inside;
}

#root h3{
  margin-top: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tox-collection__item[aria-label*="Titre\u00A01"]{
  display: none !important;
}